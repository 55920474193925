.countdown-container {
    display: flex;
    gap: 20px;
  }
  
  .countdown-unit {
    background-color: transparent;
    padding: 20px;
    border-radius: 5px;
    background-color: rgba(22, 21, 21, 0.5);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    color: #fff;
  }

  .mintCount {
    color: #fff;
    font-family: 'Squartiqa 4F', sans-serif;
  }

  .time {
    color: rgb(15, 255, 80);
}
  
  .countdown-unit > div {
    margin-top: 5px;
    font-weight: bold;
  }

  @media (max-width: 600px) {
    .countdown-container {
        flex-direction: row;
        gap: 10px;
    }

    .countdown-unit {
        width: 80px;
        height: 80px;
        padding: 15px;
    }
}

.app-store-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.app-store-logo {
  margin: 0 15px;
  width: 150px;  /* Adjust as needed */
  height: auto;
  transition: opacity 0.3s;
}

.app-store-logo:hover {
  opacity: 0.8;
}
