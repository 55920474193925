.App {
  
}
ul {
    list-style: none;
}

#myVideo {
  position: fixed; 
  right: 0; 
  bottom: 0; 
  min-width: 100%; 
  min-height: 100%;
  z-index: -1; /* Ensure video is behind other content */
}

/* 
scrollbar custom */
::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--grayAccent);
    border-radius: 8rem;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--textLightColor);
  }