@import '../../fontsTwo/style.css';
@import '../../fontsThree/style.css';
@import '../../fontsFour/style.css';
  

.infoBox2 {
    text-align: center;
    background-color: rgba(22, 21, 21, 0.5);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    color: #fff;
    padding: 10px;
    line-height: 1.5;
    word-wrap: break-word;  
    overflow-wrap: break-word;
    max-width: 800px;  /* Ensures the text doesn't stretch too wide on larger screens */
    font-size: 16px;  /* You can adjust based on your preference */
}

.infoBox2 strong {
    display: block;
    text-align: center;
}



.infoBox2 h2 {
    font-size: 20px;
    color: rgb(15, 255, 80);  /* Lighter than the main title */
    font-family: 'Squartiqa 4F', sans-serif;
}

.ptag {
    font-family: sans-serif;
    font-weight: bold;
}
