@import '../../fonts/style.css';
@import '../../fontsTwo/style.css';



.infoBox {
    color: #fff;
    text-align: center;
    border: 2px solid transparent;
    background-color: rgba(22, 21, 21, 0.5);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    cursor: pointer;
    width: auto;
    height: auto;

    @media only screen and (max-width: 768px) {
        width: 90%;  /* or any desired percentage or fixed width */
        margin: 0 auto;  /* centers the box when width is less than container width */
    }
}


.infoBox strong {
    display: block;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    text-align: center;
    font-family: sans-serif;
}

@keyframes flash {
    50%  { color: #000; background-color: #fff; }
    100% { color: #fff; background-color: rgba(50, 50, 50, 0.5); }
  }
  
  .h1 {
    color: #fff;
    text-align: center;
    border: 2px solid transparent;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: rgba(22, 21, 21, 0.5);
    border-radius: 15px;
    font-family: 'Squartiqa 4F', sans-serif;
  }

  .h2 {
    text-align: center;
    border: none;
    color: #fff;
    font-family: 'Squartiqa 4F', sans-serif;
  }

  .h3 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: 'Squartiqa 4F', sans-serif;
    border: none;
    color: #fff;
  }

  .myc-token {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;  /* Vertically aligns text if height is defined */
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    text-align: center;  /* Ensures text inside is centered */
    word-wrap: break-word;  /* Breaks the words to next line */
    overflow-wrap: break-word;  /* Breaks unusually long words */
    padding: 8px;  /* Provides a bit of spacing */
  }

  @media only screen and (max-width: 768px) {
    .infoBox {
        
    }

    .uni {
        display: grid;
        place-items: center;   /* This centers the iframe both horizontally and vertically inside the .uniswap container */
        width: 100%;           /* This ensures the container spans the full width of its parent */
        max-width: 800px;      /* Or any other maximum width you prefer */
        margin: 0 auto;        /* This centers the container itself, if it's not already */
    }

    .uni iframe {
        width: 100%;     /* Use the full width of the container */
        max-width: 800px;  /* This was the width you originally set; you can adjust if needed */
        height: 800px;    /* This was the height you originally set; you can adjust if needed */
        border: none;    /* To remove any default borders */
    }

    .uni-box {
        margin: 0 auto;
    }
    

    /* .h3 {
        display: flex;
        flex-direction: column;
    } */

    .info {
        font-size: 14px;  
        padding: 5px;
    }

    .content-row {
        margin-bottom: 15px; /* or any value that looks good to you */

        .left, .right {
            display: flex;
            flex-direction: row;
            font-size: 16px; /* adjust if the current size looks too big on mobile */
            justify-content: space-between;
        }
    }
}



@media (max-width: 768px) {   /* This is a commonly used breakpoint for tablets. You can adjust this value as needed */
    .uni {
        flex-direction: column;  /* Display in a column on mobile screens */
    }

    .uni iframe {
        width: 100%;   /* Use the full width of the container on mobile */
        max-width: 100%;
        height: 450px;   /* Adjust the height as needed for mobile screens */
    }
}

.info {
    text-align: center;
    background-color: rgba(22, 21, 21, 0.5);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    color: #fff;
    margin: 20px auto;
    padding: 10px;
    line-height: 1.5;
    word-wrap: break-word;  
    overflow-wrap: break-word;
    font-family: sans-serif;
    font-weight: bold;
    font-size: medium;
    max-width: 800px;  /* Ensures the text doesn't stretch too wide on larger screens */
}

.address {
    font-family: sans-serif;
}

.uni {
    display: flex;
    justify-content: center;
}

.chart {
    transform: translateX(15%);
}

.chart2 {
    height: 700px;
    width: 1200px;
}

@media only screen and (max-width: 768px) {
    .chart {
        transform: translateX(2%); /* Center it horizontally */
        
    }

    .chart2 {
        width: 95%;  /* Use almost the full width of the screen */
        margin: 10px auto;
    }
}

.disclaimer {
    display: block;
    margin-bottom: 20px;
  }

  .custom-bullets {
    list-style: none; 
    padding-left: 0;  
}

.custom-bullets li::before {
    content: '•';  /* Unicode character for bullet */
    color: rgb(15, 255, 80);    /* Your desired bullet color */
    font-weight: bold; /* Optionally make the bullet bold */
    display: inline-block; 
    width: 2em;    /* Space between bullet and text */
    margin-left: 1em; /* Adjust bullet position */
}

  
